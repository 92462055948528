import React from "react"
import styled, { css } from 'styled-components';
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout"
import Seo from "../components/seo"
import TopPanel from "../components/TopPanel";
// import { ABOUT_PROJECT } from '../routes';
import { Section } from "../components/section";
import { Title2, TitleWrapper } from "../components/Headings";
import { ContentWrapper } from "../components/common";
import GalleryPanel from "../components/GalleryPanel";
// import InfoModal from "../components/InfoModal";
import kitchenSvg from '../images/svg/kitchen.svg';
import bedroomSvg from '../images/svg/bedroom.svg';
import livingRoomSvg from '../images/svg/living-room.svg';
import bathRoomSvg from '../images/svg/bathroom.svg';
import downloadSrc from '../images/svg/download.svg';

const iconStyle = css`
  fill: ${({theme}) => theme.colors.icon};
  height: 60px;
  width: auto;
  margin-left: auto;

  @media(min-width: 992px) {
    margin: 0;
  }

  @media(min-width: 1200px) {
    margin-right: 32px;
  }
`;

const KitchenIcon = styled(kitchenSvg)`
  ${iconStyle};
`;

const BedroomIcon = styled(bedroomSvg)`
  ${iconStyle};
`;

const LivingRoomIcon = styled(livingRoomSvg)`
  ${iconStyle};
`;

const BathroomIcon = styled(bathRoomSvg)`
  ${iconStyle};
`;

const LargeText = styled.p`

  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  padding-right: 2rem;

  @media(min-width: 768px) {
    text-align: right;
  }
`;

const PinkSection = styled(Section)`
  background-color: ${({theme}) => theme.colors.primary};
  padding: 50px 0;

`;

const IconGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 16px;
  row-gap: 16px;

  @media (min-width: 576px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const IconWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 32px;
  justify-content: center;

  span {
    font-family: 'Oswald', Georgia, sans-serif;
    font-weight: 400;
    color: ${({theme}) =>  theme.colors.white};
    text-transform: uppercase;
    font-size: 1.5rem;
  }

  @media (min-width: 992px) {
    display: flex;
    flex-direction: column;
    gap: 32px;

    span {
      text-align: center;
    }
  }

  @media (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    gap: 0;
    justify-content: center;

    span {
      text-align: left;
    }
  }
`;

const DownloadBtn = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
  text-align: center;
  text-decoration: none;
  margin-top: 2rem;


  span {
    display: block;
    background-color: ${({theme}) => theme.colors.primary};
    color: ${({theme}) => theme.colors.white};
    padding: 1rem;
    text-transform: uppercase;
    border: 1px solid ${({theme}) => theme.colors.primary};
    transition: all 0.5s ease;
    margin-left: 1rem;
  }

  &:hover {
    text-decoration: none;

    span {
      color: ${({theme}) => theme.colors.primary};
      background-color: transparent;
    }

  }
`;

const DownloadIcon = styled(downloadSrc)`
  width: 20px;
  height: auto;
  fill: #303030;
  margin-bottom: 0.5rem;

  /* &:hover { */
    fill: ${({theme}) => theme.colors.primary};
  /* } */
`;

function AboutProjectPage () {
  return (
    <Layout>
      <Seo title="O projektu" />
      <TopPanel
        title="Poznejte bydlení reprezentující současné trendy"
        text="Třešňovka je první typicky rezidenční projekt v&nbsp;Kolíně. Co to znamená? Vysoký standard a&nbsp;uzavřený, plně vybavený a&nbsp;hlídaný areál. Jako správná rezidence se nachází na pomezí lukrativní vilové čtvrti, na skvělém místě pro život i&nbsp;cestování do okolí."
        >
        <StaticImage src="../images/panel-02-n.jpg" className="panel-image" placeholder="blurred" alt="Foto" />
      </TopPanel>
      <Section>
         <ContentWrapper>
           <TitleWrapper>
             <Title2>Stylová rezidence pro náročné</Title2>
           </TitleWrapper>
           <div className="row">
              <div className="col-md-5 offset-md-1">
                <LargeText>
                  Třešňovka je 4&nbsp;podlažní bytový komplex 26&nbsp;bytových jednotek o&nbsp;různých dispozicích, od menších 1+kk až po prostorné 3+kk.
                  <br />
                  Dokončení plánujeme na 4Q&nbsp;2025.
                </LargeText>
             </div>
              <div className="col">
                <p>Byty jsme navrhli ve velmi kvalitním provedení a&nbsp;v&nbsp;moderním vzdušném designu. Každá bytová jednotka disponuje balkonem, lodžií, terasou nebo předzahrádkou s&nbsp;výhledem do zeleně či přilehlé vilové čtvrti.</p>
                <p>Každému bytu náleží parkování a&nbsp;sklepní kóje dle vlastního výběru.</p>

                <DownloadBtn href={`/data/stavebni-standardy-v3.pdf`} title="Stáhnout stavební standardy v PDF" target="_blank" rel="noreferrer">
                  <DownloadIcon />
                  <span>Stáhněte si standardy</span>
                </DownloadBtn>
             </div>
           </div>
         </ContentWrapper>
      </Section>
      <PinkSection>
        <ContentWrapper>
          <IconGrid>
            <IconWrapper>
              <KitchenIcon />
              <span>Moderní<br />kuchyně</span>
            </IconWrapper>
            <IconWrapper>
              <BedroomIcon />
              <span>Komfortní<br />ložnice</span>
            </IconWrapper>
            <IconWrapper>
              <LivingRoomIcon />
              <span>Prosvětlený<br />obývací pokoj</span>
            </IconWrapper>
            <IconWrapper>
              <BathroomIcon />
              <span>Prostorná<br />koupelna</span>
            </IconWrapper>
          </IconGrid>
        </ContentWrapper>
      </PinkSection>
      <GalleryPanel folder="index" data={[1,2,3,4,5,6,7,8]} />
      <Section>
         <ContentWrapper>
           <TitleWrapper>
             <Title2>Uspokojí nejen moderně smýšlející</Title2>
           </TitleWrapper>
           <div className="row">
             <div className="col-md-5 offset-md-1">
               <LargeText>
                Chytrá rezidence pro chytré domácnosti, ale také objekt s&nbsp;několika technologickými vychytávkami, které šetří životní prostředí i&nbsp;peněženku – třeba díky fotovoltaice, nabíjecím stanicím pro elektromobily a&nbsp;přípravě na chytrou domácnost.
               </LargeText>
             </div>
            <div className="col">
               <p>Rezidence Třešňovka přitom myslí na vše, tedy i&nbsp;na bezpečí, pohodu a&nbsp;dostatek zeleně. K&nbsp;dispozici je prostorné hlídané parkoviště a&nbsp;pořádek střeží též kamerový systém. Pro chvíle oddechu poslouží relaxační zóna. A&nbsp;děti? Ty se vydovádí na soukromém hřišti.</p>
             </div>
           </div>
         </ContentWrapper>
      </Section>
    </Layout>
  );
}

export default AboutProjectPage;
